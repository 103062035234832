import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Courses from './components/Courses';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import './App.css';

function App() {
  const isLoggedIn = localStorage.getItem('token');
  const [menuOpen, setMenuOpen] = useState(false); // State to control the menu visibility

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close the menu after selecting a link
  };

  return (
    <Router>
      <div className="app">
        <nav className="navbar">
          {/* Hamburger menu icon */}
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          {/* Navbar links */}
          <div className={`navbar-links ${menuOpen ? 'active' : ''}`}>
            <Link to="/" onClick={closeMenu}>Home</Link>
            <Link to="/courses" onClick={closeMenu}>Courses</Link>
            <Link to="/about" onClick={closeMenu}>About Us</Link>
            <Link to="/contact" onClick={closeMenu}>Contact</Link>
            {isLoggedIn ? (
              <>
                <Link to="/dashboard" onClick={closeMenu}>Dashboard</Link>
                <Link to="/login" onClick={() => { 
                  localStorage.removeItem('token');
                  closeMenu();  // Close the menu after logout
                }}>Logout</Link>
              </>
            ) : (
              <>
                <Link to="/register" onClick={closeMenu}>Register</Link>
                <Link to="/login" onClick={closeMenu}>Login</Link>
              </>
            )}
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={isLoggedIn ? <Dashboard /> : <Login />}
          />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
