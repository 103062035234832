import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Courses.css';

const Courses = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        axios.get("http://localhost:3001/courses")
            .then(response => setCourses(response.data))
            .catch(error => console.error("Error fetching courses:", error));
    }, []);

    return (
        <div className="courses-container">
            <h2>Our Courses</h2>
            <div className="courses-grid">
                {courses.map(course => (
                    <div className="course-card" key={course.id}>
                        <h3>{course.name}</h3>
                        <p>{course.description}</p>
                        <p><strong>Duration:</strong> {course.duration}</p>
                        <p><strong>Fees:</strong> ${course.fees}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Courses;
