import React, { useState, useEffect } from 'react';
import FlashMessage from './FlashMessage';
import axios from 'axios';

const Home = () => {
    const [flashMessage, setFlashMessage] = useState('');

    useEffect(() => {
        axios.get('http://localhost:3001/flash-message')
            .then(response => {
                if (response.data) {
                    setFlashMessage(response.data.message);
                }
            })
            .catch(error => console.error("Error fetching flash message:", error));
    }, []);

    return (
        <div>
            {flashMessage && <FlashMessage message={flashMessage} duration={5000} />}
            <h1>Welcome to Our College</h1>
            <p>Explore our courses, faculty, and more.</p>
            {/* Other content for the landing page */}
        </div>
    );
};

export default Home;
