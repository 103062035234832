// Dashboard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token on logout
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="dashboard-container">
      <h2>Welcome to the Dashboard</h2>
      <button onClick={handleLogout}>Logout</button>
      {/* Add CRUD operations for admission here */}
    </div>
  );
};

export default Dashboard;
