import React, { useState, useEffect } from 'react';
import './FlashMessage.css';

const FlashMessage = ({ message, duration = 5000 }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setVisible(false), duration);
        return () => clearTimeout(timer);
    }, [duration]);

    if (!visible) return null;

    return (
        <div className="flash-message">
            <span>{message}</span>
            <button onClick={() => setVisible(false)} className="close-btn">&times;</button>
        </div>
    );
};

export default FlashMessage;
